.titulo {
    font-size: 24px;
    margin-left: 0px;
    font-weight: bold;
}

.titulo2 {
    font-size: 24px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: -2px;
    font-size: medium;
}

.subtitulo {
    font-size: 15px;
    margin-left: 43px;
    font-weight: bold;
    margin-top: -15px;
}

.back-icon {
    margin-top: 13px;
}

.sync-button {
    width: 160px;
    position: absolute;
    right: 0;
    top: -40px;
}

.titulo-container {
    display: flex;
}

.tituloShiipment {
    font-size: 16px;
}

.children-table2 {
    width: 100% !important;
}

.children-table3 {
    width: 100% !important;
    margin-top: 20px;
}

.custom-filter {
    height: 10px;
    width: 200px;
}

.custom-filter2 {
    width: 200px;
}

.home-screen-inner-container12-select {
    height: 600px;
    width: 850px;
    margin-top: 0px;
}

.home-screen-inner-container13-select {
    height: calc(100% - 110%);
    width: calc(100% - 110%);
    margin-top: 0px;
}

.mouse-row-enter {
    background-color: white;
}

.mouse-row-enter:hover {
    background-color: gray;
}

.table-column {
    font-size: 15px !important;
    font-weight: 100 !important;
    text-transform: none !important;
}

.grid-container-filterboxes {
    display: grid !important;
    grid-template-columns: 25% 25% auto auto auto;
    margin: 5px;
}

.box-container-filterboxes {
    padding-top: 3px;
    padding-left: 15px;
    padding-right: 15px;
}

.grid-child-filterboxes {
    background-color: white;
    margin-top: 10px;
}

.parent-container {
    background-color: white;
    padding: 20px;
    padding-top: 7px;
    margin: 10px;
    margin-right: 20px;
    border-radius: 1.5%;
}

.title-labels-screen {
    font-size: 2vh;
    font-weight: bold;
    padding-left: 1em;
    margin-bottom: 5px;
}

.ord-titule {
    font-size: 13px;
    font-weight: bold;
}

.ord-value {
    font-size: 17px;
}

.carga-titule {
    font-size: 13px;
    opacity: 0.5;
}

.car-value {
    font-size: 17px;
}

.mini-titule {
    font-size: 11px;
    opacity: 0.5;
}

.children-inner-container-target-with-bg-order-details {
    margin-left: 1em;
    margin-bottom: 4px;
    margin-top: 10px;
    padding: 1em;
    padding-bottom: 0px;
    padding-top: 6px;
    max-height: 30%;
    min-width: 43%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.orders-card-container {
    display: flex;
    overflow-x: scroll;
}

.children-inner-container-target-with-bg-order {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
}

.driver-title {
    font-size: 1.8vh;
    color: gray;
}

.name-driver-title {
    font-size: 2.2vh;
    font-weight: bold;
}

.plate-value {
    font-size: 1.8vh;
}

.created-date-title {
    font-size: 1.8vh;
    color: gray;
}

.created-data-value {
    font-size: 2.2vh;
    font-weight: bold;
}

.hour-value {
    font-size: 1.6vh;
    color: gray;
}

.planner-title {
    font-size: 1.8vh;
    color: gray;
}

.planner-value {
    font-size: 1.8vh;
    font-weight: bold;
}

.city-value {
    font-size: 1.8vh;
}

.children-inner-container-target-with-bg-back {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    margin-right: 10px;
}

.children--container-target-with-bg-back {
    overflow-y: scroll;
}

.children-inner-container-target-with-bg-products {
    min-width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    height: 100%;
    overflow-y: scroll;
    margin-right: 15px;
}

.children-inner-container-target-with-bg-order-producst {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    margin: 15px;
    margin-top: 0px;
    padding-bottom: 0px !important;
    padding-top: 7px !important;
}

.children-evidence {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    margin-right: 5px;
    padding: 15px;
    padding-top: 0px;
    padding-bottom: 5px;
    overflow-x: scroll;
    height: 100%;
}

.drive-details-label {
    font-size: 1.7vh;
    color: gray;
}

.children-inner-container-target-with-bg-order-details-cointainer {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    height: 100%;
}

.selected-status {
    background: linear-gradient(to bottom, #f38e8e, #cc0000);
}

.selected-status img {
    width: 60px;
    height: auto;
}

.drive-details-value {
    font-size: 1.5vh;
    font-weight: bold;
}

.parent {
    display: flex;
    height: 100vh;
}

.pagination-component-routesBatch {
    margin-bottom: 20px;
    position: sticky;
}

.css-a5rdam-MuiGrid-root .MuiGrid-item {
    padding-top: 0px !important;
}

.title-labels-screen-picture {
    font-size: 17px;
    font-weight: bold;
    padding-left: 1em;
    margin-bottom: 0px;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.card-telemetry {
    position: absolute;
    top: 10px;
    /* Ajusta la distancia desde la parte superior */
    right: 10px;
    /* Ajusta la distancia desde la parte derecha */
    background-color: white;
    /* Para que sea visible sobre el mapa */
    padding: 10px;
    border-radius: 8px;
    /* Opcional: mejora el aspecto con bordes redondeados */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Opcional: agrega sombra para darle profundidad */
    max-width: 260px;
}

.children-inner-container-target-with-bg-map {
    position: relative;
}

.div-route-card {
    max-height: 0vh;
}

.check-icon-container-selected {
    background-color: var(--primaryColor) !important;
}

.children-inner-container-with-bg-completedRoute {
    height: calc(100vh - 96px);
}

.grid-container {
    height: 100%;
}

.mini-grid-list-products {
    height: 100%;
}

.map-route-rutas-finalizadas {
    height: 100% !important;
}