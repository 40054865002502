.ant-typography.ant-typography-secondary {
    font-size: 0.8em;
}

.mapa-google, #mapa-google-map{
    height: calc(100vh - 68px) !important;
    width: 100% !important;
}

.home-screen-container{
    padding-left: 40px;
    padding-right: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.home-screen-inner-col-container1{
    height: calc(100vh - 106px);
    opacity: 0.95;
}


.home-screen-inner-container12{
    background-color: white;
    height: calc(100% - 220px);;
    width: 100%;
    max-width: 250px;
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.home-screen-inner-container12-select{
    height: calc(100% - 0px);;
    margin-top: 0px;
}

.home-screen-inner-col-container2{
    height: calc(100vh - 106px);
    opacity: 0.95;
}

.home-screen-inner-container2{
    background-color: white;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.home-screen-inner-col-container-hidden{
    display: none;
}

.vehicle-details-header{
    width: 100%;
    height: 52px;
    color: black;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-top: 17px;
    padding-left: 12px;
    background-color: whitesmoke;
    display: flex;
}

#vehicle-details-icon-container{
    background-color: var(--primaryColor);
    border-radius: 30px;
    padding: 5px;
    width: 36px;
    height: 36px;
}

#vehicle-details-icon{
    fill: white;
    width: 100%;
    height: 100%;
}

#vehicle-details-label{
    color: #37474f !important;
    font-size: 0.8vw;
    font-weight: 600;
    margin-left: 0px;
}

.driver-details-container{
    width: 100%;
    height: auto;
    color: #37474f;
    font-size: 0.8vw;
}

.driver-details-item{
    width: 100%;
    height: auto;
    border-bottom-color: rgba(0,0,0, 0.1);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 12px;
    padding-right: 12px;
    align-items: flex-start;
}

.driver-details-item-label{
    font-weight: 500;
    display: block; /* Asegura que pueda crecer */
  white-space: normal;
  word-break: break-word;
}

.driver-details-item-value{
    font-weight: 600;
    text-align: right;
    margin: 0; 
    white-space: normal;
    word-break: break-word;
}

.driver-details-item-value  p{
    margin: 0; 
  white-space: normal;
  word-break: break-word;
}

.driver-details-container2{
    width: 100%;
    height: auto;
    color: #37474f;
    font-size: 0.8vw;
}

.driver-details-item2{
    width: 100%;
    height: auto;
}

.driver-details-item-label2{
    height: auto;
    font-weight: 500;
    padding-top: 13px;
    padding-bottom: 0px;
    padding-left: 12px;
}

.driver-details-item-value2{    
    height: calc(100vh - 540px);
    overflow-y: scroll;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
}

.driver-details-item-live-icon-container{
    height: auto;
    width: 100%;
    text-align: center;
    font-weight: 500;
    padding-top: 14px;
}


.driver-details-timeline-item{
    height: auto;
    transition: all 0.25s;
}

.driver-details-timeline-item:hover{
    cursor: pointer;
}

.driver-details-timeline-item:active{
    background-color: whitesmoke;
}

.driver-details-timeline-item-label1{
    color: #37474f;
    font-size: 0.8vw;
    font-weight: 700;
    text-align: left;
    margin-top: 5px;
}
.Editar_Orden_HeaderUploadScreen2{
    width: 20px;
    height: 20px;
    left: 4px;
    top: -3px;
}
.Editar_Orden_HeaderUploadScreenBotton2{
    width: 10px;
    height: 25px;
    text-align: left;
}
.driver-details-timeline-item-label2{
    color: #37474f;
    font-size: 0.7vw;
    font-weight: 500;
    text-align: right;
}

.driver-details-timeline-item-label3{
    color: #37474f;
    font-size: 0.7vw;
    font-weight: 500;
    text-align: left;
    margin-bottom: 0px;
}

.driver-details-timeline-item-label4{
    color: #37474f;
    font-size: 0.7vw;
    font-weight: 500;
    text-align: right;
}
.driver-details-timeline-item-label6{
    color: #37474f;
    font-size: 0.7vw;
    font-weight: 500;
    text-align: right;
}
.driver-details-timeline-item-label20{
    color: #37474f;
    font-size: 0.7vw;
    font-weight: 500;
    text-align: right;
    margin-top: -5px;
}
.driver-details-timeline-item-label21{
    color: #37474f;
    font-size: 0.7vw;
    font-weight: 500;
    text-align: right;

}
.driver-details-timeline-item-label22{
    color: #37474f;
    font-size: 0.7vw;
    font-weight: 500;
    text-align: right;

}

.driver-details-timeline-item-label5{
    color: #37474f;
    font-size: 0.7vw;
    font-weight: 700;
    text-align: right;
}

.vehicle-details-container{
    width: 100%;
    height: auto;
    text-align: center;
}


.vehicle-details1-container{
    background-color: var(--primaryColor);
    height: 100px;
    width: 100%;
    max-width: 250px;
    margin-top: 0px;
    border-radius: 4px;
    padding-top: 3px;
    padding-left: 12px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}


.vehicle-details2-container{
    background-color: #343a40;
    height: 100px;
    width: 100%;
    max-width: 250px;
    margin-top: 10px;
    border-radius: 4px;
    padding-top: 3px;
    padding-left: 12px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.vehicle-indicator-container{
    width: 100%;
    height: 100%;
    padding: 16px;
    overflow:hidden;
}

.vehicle-indicator-icon{
    color: white;
    opacity: 50%;
    font-size: 95px;
}

.vehicle-indicator-label{
    font-size: .875rem;
    color: white;
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: left;
}

.vehicle-indicator-cumulative{
    font-size: 2rem;
    color: white;
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: 0;
}

.vehicle-indicator-value{
    font-size: 13px;
    color: white;
    font-weight: 700;
    text-align: left;
}

.vehicle-indicator-value2{
    font-size: 10px;
    color: white;
    font-weight: 500;
    text-align: left;
    margin: 0;
    padding: 0;
}

.vehicle-indicator-value3{
    font-size: 11px;
    color: white;
    font-weight: 700;
    text-align: left;
    margin: 0;
    padding: 0;
}

.vehicle-indicator-icon2{
    color: white;
}

.bottom_button_cancel{
    margin-top: 0;
    margin-left: 31vh;
    background-color: rgb(255, 0, 0);
    border: 2px solid rgb(255, 162, 162);
    width: 100px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 10px 5px 5px rgb(0, 0, 0, 0.2);
}

.bottom_button_cancel:hover{
    background-color: rgb(255, 56, 56);
    border: 2px solid rgb(255, 56, 56);
    color: rgb(255, 255, 255);
}

.bottom_button_update_delivery{
    margin-top: 0;
    margin-left: 0;
    background-color: rgb(46, 194, 13);
    border: 2px solid rgb(127, 173, 117);
    width: 100px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 10px 5px 5px rgb(0, 0, 0, 0.2);
}

.bottom_button_update_delivery:hover{
    background-color: rgb(87, 183, 66);
    border: 2px solid rgb(87, 183, 66);
    color: rgb(255, 255, 255);
}
