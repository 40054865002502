.tms-container {
    height: calc(100vh - 68px);
    width: 100%;
}

.container-search {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: -10px;
    margin-right: 20px;
}

.label-search-carrier {
    transform: translate(10px, -15px) scale(0.75) !important;
}

.tms-inner-container {
    position: relative;
    background-color: white;
    width: 97%;
    height: 94%;
    left: 1.5%;
    top: 3%;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    margin-left: 50px;
}

.tms-loading {
    position: relative;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
}

.tms-filters-container {
    padding-top: 2px;
    width: 100%;
    height: 160px;
    box-shadow: 0px 1px 12px 6px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

.tms-round-button {
    position: absolute !important;
    right: 35px;
    top: 35px;
    background-color: var(--primaryColor) !important;
    color: white !important;
}

.tms-round-button:hover {
    opacity: 0.8;
}

.tms-title {
    font-size: 24px;
    font-weight: 600;
    margin-left: 30px;
    margin-top: 4px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.tms-search-date-container {
    display: flex;
    align-items: center;
    border: none;
    justify-content: space-between;
}

.tms-search-status-container {
    display: flex;
    align-items: center;
    border: none;
    margin-top: 10px;
    margin-right: 15px;
    border-radius: 5px;
}

.tms-search-status-container .MuiCheckbox-colorSecondary.Mui-checked {
    color: red !important;
}

.tms-search-status-container .MuiFormControl-root {
    margin-top: 8px !important;
    width: 250px;
    background-color: white !important;
    display: flex !important;
    flex-grow: 1 !important;
    border: none !important;
    border-radius: 5px !important;
    height: 40px !important;
}

.tms-search-status-container label {
    display: flex;
    align-items: center;
}

.tms-search-status-container label.Mui-focused {
    color: red;
}

.tms-search-status-container .MuiOutlinedInput-root {
    border: none !important;
    /* Elimina el borde */
    box-shadow: none !important;
    /* Elimina la sombra */
    height: 40px !important;
    /* Altura del input */
    border-radius: 5px;
    width: auto;
}

.tms-search-status-container .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.tms-search-status-container .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.tms-search-status-container .MuiOutlinedInput-notchedOutline {
    border: none !important;
    /* Elimina el borde en todos los estados */
}

.tms-search-status-container.Mui-focused {
    border: none !important;
    box-shadow: none;
}

.tms-search-status-container.Mui-error {
    border: none !important;
}

.tms-search-textfield {
    margin-top: 0px !important;
    width: calc(33% - 65px) !important;
    background-color: white !important;
    display: flex !important;
    flex-grow: 1 !important;
    border: none !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    outline: none !important;
}

.tms-search-textfield label.Mui-focused {
    color: red;
}

.tms-search-textfield .MuiOutlinedInput-root {
    border: none !important;
    /* Elimina el borde */
    box-shadow: none !important;
    /* Elimina la sombra */
    height: 48px !important;
    /* Altura del input */
    font-weight: bold !important;
    width: auto;
    padding-left: 0px;
    font-size: 14px;
}

.tms-search-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.tms-search-textfield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.tms-search-textfield .MuiOutlinedInput-notchedOutline {
    border: none !important;
    /* Elimina el borde en todos los estados */
}

.tms-search-textfield.Mui-focused {
    border: none !important;
    box-shadow: none;
}

.tms-search-textfield.Mui-error {
    border: none !important;
}

.tms-search-textfield-date {
    margin-top: 0px !important;
    margin-left: 10px !important;
    width: 160px !important;
    background-color: white !important;
    display: flex !important;
    flex-grow: 1 !important;
    margin-right: 10px !important;
    border: none !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    outline: none !important;
    margin-bottom: 0px !important;
}

.tms-search-textfield-date label.Mui-focused {
    color: red;
}

.tms-search-textfield-date .MuiOutlinedInput-root {
    border: none !important;
    /* Elimina el borde */
    box-shadow: none !important;
    /* Elimina la sombra */
    height: 40px !important;
    /* Altura del input */
    font-weight: bold !important;
    width: auto;
}

.tms-search-textfield-date .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.tms-search-textfield-date .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.tms-search-textfield-date .MuiOutlinedInput-notchedOutline {
    border: none !important;
    /* Elimina el borde en todos los estados */
}

.tms-search-textfield-date.Mui-focused {
    border: none !important;
    box-shadow: none;
}

.tms-search-textfield-date.Mui-error {
    border: none !important;
}

.tms-filters-separator {
    position: absolute !important;
    content: "";
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    width: 1px;
    height: 130px;
    top: 40px;
    left: 33%;
}

.tms-filters-separator2 {
    position: absolute !important;
    content: "";
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    width: 1px;
    height: 130px;
    top: 40px;
    left: 66%;
}

.tms-filters-dispatched-container {
    position: absolute !important;
    width: 33%;
    top: 38px;
    left: calc(33% + 20px);
    display: flex;
    flex-direction: row;
    padding-top: 25px;
    z-index: 10;
}

.tms-filters-dispatched-title {
    position: absolute !important;
    top: 0px;
    font-size: 14px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.tms-filters-dispatched-button-group {
    width: calc(100% - 37px) !important;
}

.tms-filters-dispatched-container2 {
    position: absolute !important;
    width: 33%;
    top: 38px;
    left: calc(33% + 20px);
    display: flex;
    flex-direction: row;
    padding-top: 25px;
}

.tms-filters-dispatched-title2 {
    position: absolute !important;
    top: 70px;
    font-size: 14px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.tms-filters-dispatched-button2 {
    margin-top: 70px !important;
}

.tms-filters-pagination-container {
    position: absolute !important;
    width: 33%;
    top: 120px;
    left: calc(66% + 20px);
    display: flex;
    flex-direction: row;
}

.tms-filters-pagination-button {
    margin-top: 2px !important;
    height: 52px;
    border-radius: 10px !important;
    margin-right: 10px !important;
}

.tms-filters-pagination-count-text {
    margin-top: 20px !important;
    font-size: 46px !important;
    font-weight: bold;
}

.button-filter-icon {
    background-color: white !important;
    border: none !important;
    border-radius: 50% !important;
    padding: 8px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 40px !important;
    height: 40px !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.button-filter-icon svg {
    height: 28px !important;
    width: 28px !important;
}

.filter-icon {
    cursor: pointer;
}

.button-filter-icon-cargas {
    background-color: white !important;
    border: none !important;
    border-radius: 50% !important;
    padding: 8px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 45px !important;
    height: 45px !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.button-filter-icon-cargas svg {
    height: 30px !important;
    width: 30px !important;
}

.filter-icon-cargas {
    cursor: pointer;
}

.button-filter-icon-shipping {
    background-color: white !important;
    border: none !important;
    border-radius: 50% !important;
    padding: 8px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 50px !important;
    height: 50px !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 20px;
}

.button-filter-icon-shipping svg {
    height: 28px !important;
    width: 28px !important;
}

.filter-icon-shipping {
    cursor: pointer;
}

.card-style {
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.card-style:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-container {
    padding: 2px 16px;
}

.tarjeta-header-padre {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    height: 20px;
}

.tarjeta-carga {
    border-radius: 17px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    margin-top: 5px;
}

.tarjeta-header h2 {
    margin: 0;
    color: #333;
    font-weight: bold;
    font-size: 20px;
}

.tarjeta-header h3 {
    margin: 0;
    color: black;
    font-size: 15px;
}

h3 {
    font-size: 14px;
}

h5 {
    font-size: 12px;
    color: rgb(0, 0, 0);
    font-weight: bold;
}

.tarjeta-header h4 {
    margin: 0;
    color: gray;
    font-size: 13px;
}

.tarjeta-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.tarjeta-detalles {
    display: flex;
    justify-content: space-between;
}

.tarjeta-detalles-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 20px;
    padding-bottom: 10px;
}

.tarjeta-detalles-column p {
    margin: 0;
    font-weight: 500;
}

.tarjeta-detalles-column h5 {
    margin: 0;
    color: rgb(0, 0, 0);
    font-weight: bold
}

.tarjeta-estado {
    padding: 10px 20px;
    background-color: white;
    color: #2f6627;
    border-radius: 20px;
    font-weight: bold;
    white-space: nowrap;
}

.image-estatus {
    background-color: white;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    border-top-left-radius: 110px;
    border-top-right-radius: 35px;
    border-bottom-left-radius: 15px;
    position: absolute;
    right: -20px;
    top: 35px;
    width: 150px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
}

.selected-status {
    background: #b8abab00;
    border: none;
    /*
    transition: background-color 0.3s ease;
    border-top-left-radius: 100%;
    */
}

.tarjeta-carga.selected {
    /*border: 2px solid #cc0000;*/
    /* Cambia el borde para indicar selección */
    /*background-color: #cc0000*/
    /* background: linear-gradient(to bottom, #f38e8e, #cc0000); */
    background: linear-gradient(to bottom, #ebe8e8, #c2c0c0);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    /* Color de fondo ligero */
}

.image-estatus img {
    width: 100px;
    height: auto;
}

.detail-carga-visible .tarjeta-detalles-column.important {
    display: none;
}

.container-carga {
    width: calc(100%);
}

.container-carga .children-inner-container-with-bg {}

.containerTms {
    height: calc(100vh);
}

.container-padre {
    display: flex;
    justify-content: space-between;
}

.children-inner-container-with-bg {
    transition: width 0.3s ease-in-out;
    box-shadow: none;
    overflow-y: auto;
}

.children-inner-container-with-bg::-webkit-scrollbar {
    width: 12px;
}

.children-inner-container-with-bg::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 15px;
}

.children-inner-container-with-bg::-webkit-scrollbar-thumb {
    background-color: #84858d;
    border-radius: 15px;
    border: 1px solid #84858d;
}

.children-inner-container-with-bg::-webkit-scrollbar-thumb:hover {
    background-color: #84858d;
    border: 1px solid #84858d;
}

.children-inner-container-with-bg-detail-visible {
    max-height: 35%;
    /* Ajusta el alto según el contenido */
    width: calc(100% - 40px);
    overflow-y: auto;
    margin-bottom: 5px;
}

.children-inner-container-with-bg-detail-visible::-webkit-scrollbar {
    width: 12px;
}

.children-inner-container-with-bg-detail-visible::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 15px;
}

.children-inner-container-with-bg-detail-visible::-webkit-scrollbar-thumb {
    background-color: #84858d;
    border-radius: 15px;
    border: 3px solid #84858d;
}

.children-inner-container-with-bg-detail-visible::-webkit-scrollbar-thumb:hover {
    background-color: #84858d;
    border: 3px solid #84858d;
}

.container-details {
    /* Inicialmente no se muestra */
    display: none;
    transition: width 0.3s ease-in-out;
    overflow: hidden;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    max-width: 400px;
    /* O el ancho que prefieras */
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 0px;
    height: 729px;
    overflow-y: auto;
}

.container-details::-webkit-scrollbar {
    width: 12px;
}

.container-details::-webkit-scrollbar-track {
    background-color: #d5d5db;
    border-radius: 15px;
}

.container-details::-webkit-scrollbar-thumb {
    background-color: #84858d;
    border-radius: 15px;
    border: 3px solid #84858d;
}

.container-details::-webkit-scrollbar-thumb:hover {
    background-color: #84858d;
    border: 3px solid #84858d;
}


/* Estilos cuando el detalle está visible */

.container-details-visible {
    display: block;
    margin-left: 17px;
    width: 343px;
    margin-bottom: 18px;
}

.container-details-contracted {
    max-height: 320px;
}

.header-detalle-carga {
    display: flex;
    justify-content: space-between;
}

.header-detalle-carga button {
    display: flex;
    align-items: center;
    background-color: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.shipping-list {
    /* Ajusta los estilos de este contenedor según sea necesario */
    transition: width 0.3s ease-in-out;
    width: calc(100% - 40px);
    padding: 5px 20px;
    background: white;
    /* color de fondo */
    border-radius: 15px;
    /* bordes redondeados */
    margin-left: 10px;
    max-height: 290px;
    margin-bottom: 0;
    margin-top: 0;
}

.container-info-envios {
    transition: width 0.3s ease-in-out;
    width: 343px;
    padding: 20px;
    background: white;
    border-radius: 15px;
    margin-left: 18px;
    margin-bottom: 15px;
    height: 387px;
    overflow-y: auto;
}

.container-info-envios::-webkit-scrollbar {
    width: 12px;
}

.container-info-envios::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 15px;
}

.container-info-envios::-webkit-scrollbar-thumb {
    background-color: #e7e7e7;
    border-radius: 15px;
    border: 3px solid #f1f1f1;
}

.container-info-envios::-webkit-scrollbar-thumb:hover {
    background-color: #d4d4d4;
    border: 3px solid #f1f1f1;
}

.shipping-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.shipping-cards-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    /* espacio entre tarjetas */
    overflow-x: auto;
    margin: 0;
    height: 170px;
    max-width: 1230px;
}

.shipping-cards-container::-webkit-scrollbar {
    margin: 0;
    height: 10px;
}

.shipping-cards-container::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 15px;
    margin: 0;
}

.shipping-cards-container::-webkit-scrollbar-thumb {
    background-color: #e7e7e7;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
    margin: 0;
}

.shipping-cards-container::-webkit-scrollbar-thumb:hover {
    background-color: #d4d4d4;
    border: 2px solid #f1f1f1;
}

.shipping-card {
    flex: 0 0 auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    /* distribuye el espacio entre los elementos */
    width: 350px;
    cursor: pointer;
    border: 2px solid transparent;
    /* Asegúrate de tener un borde definido para la transición */
    transition: border 0.3s ease-in-out;
    line-height: 1.2;
    justify-content: center;
    height: 170px;
}

.shipping-card.selected-carga {
    border: 2px solid var(--primaryColor);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: -0.2px;
    width: 340px;
}

.line-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    /* distribuye el espacio entre los elementos */
    width: 300px;
    max-height: 148px;
}

.line-titulo h4 {
    margin: 0;
    color: gray;
    font-size: smaller;
}

.line-id {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.line-id h2 {
    font-weight: bold;
    margin: 0;
    font-size: medium;
}

.line-name h2 {
    margin: 0;
    font-size: medium;
}

.line-detalles {
    display: flex;
    justify-content: space-between;
}

.line-detalles-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 15px;
    padding-bottom: 10px;
}

.line-detalles-column p {
    margin: 0;
    font-weight: 500;
}

.line-detalles-column h5 {
    margin: 0;
    color: rgb(0, 0, 0);
    font-weight: bold
}

.shipping-details {
    display: flex;
    align-items: center;
    gap: 10px;
    /* espacio entre id y el icono de estado */
}

.shipping-status span {
    /* Estilo del icono de estado */
    display: inline-block;
    width: 24px;
    /* tamaño del icono */
    height: 24px;
    line-height: 24px;
    /* centra verticalmente el texto en el span */
    text-align: center;
    background: #dff0d8;
    /* color de fondo del ícono */
    color: #3c763d;
    /* color del checkmark */
    border-radius: 50%;
    /* hace el fondo redondo */
    font-weight: bold;
}

.shipping-id {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.shipping-id h4 {
    color: gray;
    margin: 0;
}

.shipping-id-id {
    font-weight: bold;
    margin: 0;
}

.shipping-id-id h2 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.shipping-address h2 {
    font-size: 20px;
}

.shipping-detalles {
    display: flex;
    justify-content: space-between;
}

.shipping-detalles-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 15px;
    padding-bottom: 10px;
}

.shipping-detalles-column p {
    margin: 0;
    font-weight: 500;
}

.shipping-detalles-column h5 {
    margin: 0;
    color: rgb(0, 0, 0);
    font-weight: bold
}

.check-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: white;
    /* Fondo blanco para el círculo */
    width: 40px;
    /* Tamaño del contenedor del icono */
    height: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Sombra ligera */
    margin-left: auto;
}

.check-icon-container-selected {
    background: linear-gradient(to bottom, #f38e8e, #cc0000);
    margin-top: 5px;
    color: white;
}

.check-icon-container-selected svg {
    fill: #fff;
    /* Color blanco para el ícono */
}

.check-icon {
    width: 5px;
}

.container-action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.container-action-buttons.map-selected .action-buttons-container {
    width: calc(100% - 118px);
    /* Ajusta este valor según el ancho de tu nuevo botón */
    transition: width 0.3s ease;
    /* Para una transición suave */
}

.container-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 15px;
}

.container-buttons p {
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
}

.action-buttons-container {
    display: flex;
    width: calc(100% - 40px);
    height: 115px;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    background-color: #fff;
    /* O el color que prefieras */
    margin-bottom: 0;
    margin-left: 18px;
    border-radius: 15px;
}

.action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #f38e8e, #cc0000);
    /* Color rojo para el fondo del botón */
    color: #fff;
    /* Texto blanco */
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    /* Bordes redondeados */
    display: flex;
    align-items: center;
    gap: 5px;
    /* Espacio entre el ícono y el texto */
    cursor: pointer;
    transition: background-color 0.3s;
    width: 60px;
    height: 40px;
}

.action-button:hover {
    background-color: #d32f2f;
    /* Color rojo más oscuro al pasar el mouse */
}

.action-button:disabled {
    opacity: 0.5;
    /* Reducir la opacidad */
    cursor: not-allowed;
    /* Cambiar el cursor */
    /* Otros estilos que desees aplicar */
}

.action-button svg {
    fill: #fff;
    /* Color blanco para los íconos */
    width: 100%;
    height: 100%;
}

.modify-location-button {
    background: #fff;
    /* Ejemplo de color de fondo */
    color: white;
    /* Color del texto */
    /* Otros estilos para tu botón */
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;
    width: 100px;
    height: 50px;
    border: none;
    display: flex;
    align-items: center;
    /* Ajusta para que se alinee con tus otros botones */
}

.modify-location-button:disabled {
    opacity: 0.5;
    /* Reducir la opacidad del botón */
    cursor: not-allowed;
    /* Cambiar el cursor a "no permitido" */
    /* Otros estilos que desees aplicar cuando el botón esté deshabilitado */
}

.modify-location-button img {
    width: 100px;
    height: 70px;
    fill: #fff;
    /* Color blanco para los íconos */
    /* Ajusta el tamaño del ícono según tus necesidades */
}

.container-modify-location {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    /* Ajusta según tus necesidades */
    background-color: #fff;
    border-radius: 15px;
    height: 120px;
    width: 170px;
    margin-right: 28px;
    margin-left: 29px;
    /* Añade más estilos si es necesario */
}

.container-modify-location p {
    margin: 0;
    font-size: medium;
}

.pagination-container-padre {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.my-pagination-controls {
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    position: sticky;
    z-index: 1000;
    top: 0;
    background-color: white;
}

.pagination {
    display: flex;
    justify-content: flex-start;
}

.pagination-select {
    display: block;
    /* justify-content: flex-end; */
    flexDirection: "column";
}

.pagination-select {
    padding: 5px;
}

.my-pagination .MuiPagination-ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:13px;
}

.my-pagination2 .MuiPagination-ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:0px;
}

.my-pagination .MuiPaginationItem-root {
    color: #000;
    /* Cambia el color según necesites */
    /* Borde para los botones */
    margin: 0 4px;
    /* Espacio entre botones */
    border-radius: 4px;
    /* Bordes redondeados */
    /* Añade cualquier otro estilo que necesites */
    margin-top: 10px;
}

.my-pagination .Mui-selected {
    background-color: white;
    /* Fondo para el botón seleccionado */
    color: black;
    /* Texto para el botón seleccionado */
    /* Añade cualquier otro estilo que necesites */
}


/* Estilos para el Select */

.my-select {
    margin-left: 10px;
    /* Espacio a la izquierda del select */
}

.my-select .MuiSelect-select {
    padding: 8px 24px 8px 12px;
    /* Espaciado interno para el select */
    border-radius: 4px;
    /* Bordes redondeados */
    /* Borde del select */
    /* Añade cualquier otro estilo que necesites */
}

.my-pagination {
    border-radius: 10px;
    padding: 3px;
    margin-right: 18px;
}

.hidden {
    display: none;
}

.info-envios-container::-webkit-scrollbar {
    width: 12px;
}

.info-envios-container::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 15px;
    margin: 0;
}

.info-envios-container::-webkit-scrollbar-thumb{
  background-color: #e7e7e7;
  border-radius: 15px;
  border: 2px solid #f1f1f1;
  margin: 0;
}

.info-envios-container::-webkit-scrollbar-thumb:hover {
    background-color: #d4d4d4;
    border: 2px solid #f1f1f1;
}

.truncated-text {
    position: relative;
    display: inline-block;
}

.tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    background-color: white;
    color: black;
    padding: 5px 10px;
    /* Ajusta el padding horizontal y vertical según tus necesidades */
    border-radius: 5px;
    top: -30px;
    /* Ajusta la posición vertical del tooltip según tus necesidades */
    left: 50%;
    /* Lo posiciona en el centro horizontal */
    transform: translateX(-50%);
    /* Centra el tooltip horizontalmente */
    white-space: nowrap;
    /* Evita que el texto del tooltip se divida en varias líneas */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.truncated-text:hover .tooltip {
    visibility: visible;
}

.map-modal {
    position: fixed;
    /* Posición fija respecto al viewport */
    top: 0;
    /* Alineado arriba */
    left: 0;
    /* Alineado a la izquierda */
    width: 95%;
    /* Ancho total del viewport */
    height: 100%;
    /* Altura total del viewport */
    background-color: rgba(0, 0, 0, 0.5);
    /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    align-items: start;
    margin-left: 84px;
    margin-top: 69px;
    padding-top: 50px;
    z-index: 1000;
    /* Asegura que esté por encima de otros elementos */
}

.map-modal-content {
    display: flex;
    position: relative;
    /* Posición relativa para posicionar el botón de cerrar */
    width: 90%;
    /* El ancho que quieras para el mapa */
    height: 90%;
    /* La altura que quieras para el mapa */
    background-color: #fff;
    /* Fondo blanco para el contenido */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* Sombra para resaltar el modal */
    border-radius: 15px;
    /* Bordes redondeados para el contenido */
    max-height: 600px;
}

.map-modal-header {
    position: absolute;
    width: 400px;
    height: 80px;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    /* Centra el elemento */
    /* Ajusta el ancho automáticamente según el contenido */
    background-color: white;
    padding: 10px 20px;
    /* Espacio interior para que el texto no toque los bordes */
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    /* Ligera sombra para profundidad */
    z-index: 10;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.map-modal-title {
    font-size: 1.5em;
    /* Tamaño del título */
    display: flex;
}

.map-modal-shipment-id {
    display: flex;
    align-items: center;
    font-size: 1em;
    /* Tamaño del identificador del envío */
}

.map-modal-close-button {
    position: absolute;
    /* Posición absoluta respecto al contenedor */
    top: 20px;
    /* Espaciado desde arriba */
    right: 20px;
    /* Espaciado desde la derecha */
    background: white;
    /* Fondo rojo para el botón */
    color: black;
    /* Texto blanco */
    border: none;
    /* Sin bordes */
    border-radius: 50%;
    /* Redondear el botón */
    padding-top: 7px;
    cursor: pointer;
    /* Cursor de mano al pasar por encima */
    z-index: 1001;
    /* Asegura que esté por encima del contenido */
    width: 50px;
    height: 50px;
}

.map-modal-close-button .closeIcon {
    width: 100%;
    height: 100%;
}

.map-modal-footer {
    position: absolute;
    z-index: 1001;
    top: 70%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.complemento-container {
    position: absolute;
    z-index: 1001;
    top: 83%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
}

.map-modal-footer select {
    width: 12%;
    height: 50px;
    margin-right: 10px;
    margin-left: 20px;
    padding: 5px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    /* Ligera sombra para profundidad */
    font-size: 20px;
    font-weight: semibold;
}

.map-modal-footer select:focus {
    outline: 1px solid #cccccc;
    /* Cambia el color y el estilo del borde a algo más sutil */
    /* Otras propiedades para mejorar el enfoque, como un ligero fondo u otro efecto visual */
    box-shadow: 0 0 3px #aaaaaa;
    /* Añade un suave sombreado como indicador de enfoque */
}

.map-modal-footer .direccion-input {
    padding: 5px;
    width: 30%;
    height: 50px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    /* Ligera sombra para profundidad */
    margin-right: 25px;
    margin-left: 20px;
    font-size: 20px;
    font-weight: semibold;
}

.complemento-container .complemento-input {
    padding: 5px;
    width: 90%;
    height: 50px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    /* Ligera sombra para profundidad */
    margin-right: 25px;
    margin-left: 20px;
    font-size: 20px;
    font-weight: semibold;
}

.map-modal-footer input:focus {
    outline: 1px solid #cccccc;
    /* Cambia el color y el estilo del borde a algo más sutil */
    /* Otras propiedades para mejorar el enfoque, como un ligero fondo u otro efecto visual */
    box-shadow: 0 0 3px #aaaaaa;
}

.complemento-container input:focus {
    outline: 1px solid #cccccc;
    /* Cambia el color y el estilo del borde a algo más sutil */
    /* Otras propiedades para mejorar el enfoque, como un ligero fondo u otro efecto visual */
    box-shadow: 0 0 3px #aaaaaa;
}

.map-modal-footer .latitud-input {
    padding: 5px;
    width: 10%;
    height: 50px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    /* Ligera sombra para profundidad */
    margin-right: 25px;
    font-size: 20px;
    font-weight: semibold;
}

.map-content {
    max-height: 500px !important;
}

.map-modal-footer .longitud-input {
    padding: 5px;
    width: 10%;
    height: 50px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    /* Ligera sombra para profundidad */
    margin-right: 10px;
    font-size: 20px;
    font-weight: semibold;
}

.map-modal-footer button {
    padding: 5px 15px;
    width: 55px;
    height: 55px;
    background: linear-gradient(to bottom, #f38e8e, #cc0000);
    /* Color rojo para el fondo del botón */
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.save-icon {
    width: 100%;
}